import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTrophy, FaSnowflake, FaAward } from 'react-icons/fa';

const RuleContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.textPrimary};
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const LanguageSwitch = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  button {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.textPrimary};
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 10px;
    transition: background 0.3s;

    &:hover {
      background: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
  text-align: center;
`;

const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
  font-size: 1.5rem;
`;

const RuleText = styled.p`
  margin-bottom: 15px;
  line-height: 1.8;
  font-size: 1.1rem;
`;

const SpecialSection = styled.div`
  margin: 20px 0;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  color: ${({ theme }) => theme.colors.textPrimary};
  border-left: 5px solid ${({ theme }) => theme.colors.primaryDark};
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    transform: scale(1.02);
  }
`;

const SpecialIcon = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const SpecialText = styled.div`
  flex: 1;
`;

const rules = {
  english: {
    title: "Game Rules - Triple Freezer",
    objective: "Game Objective",
    setup: "Setup",
    throwing: "Throwing Rules",
    scoring: "Scoring",
    special: "Special Events",
    winning: "Winning the Game",
    content: {
      objective: `Players aim to reach at least 90 points by throwing dice. Once a player reaches 90 points, they get a chance to throw a winning roll that needs to score at least 9 points.`,
      setup: `Players: 2-4 players (recommended)\nComponents:\n- Triple Freezer arena\n- 3 Acrylic dice\n- 3 Wooden dice`,
      throwing: `Each player shakes the dice at least twice and then has 6 seconds to throw the dice into the arena. All dice must be released before the player’s hand crosses the arena edge.`,
      scoring: `Points are awarded based on dice values that land in the scoring area. Only dice that land within the circle count as points. If all 3 dice land inside the circle, points are doubled.`,
      special: `Special Events:\n1. Triplat - All 3 dice land in the circle.\n2. Triple Freeze - All 3 dice show 6 and land in the freeze zone, resulting in an instant win.\n3. Tripla-Triplat - All 3 dice show the same value, granting 90 points.`,
      winning: `To win, a player must reach at least 90 points and throw a final roll that scores at least 9 points.`
    }
  },
  finnish: {
    title: "Pelin Säännöt - Triple Freezer",
    objective: "Pelin Tavoite",
    setup: "Pelivalmistelut",
    throwing: "Heittojen Säännöt",
    scoring: "Pisteytys",
    special: "Erikoistilanteet",
    winning: "Pelivoitto",
    content: {
      objective: `Pelaajat pyrkivät saavuttamaan vähintään 90 pistettä heittämällä noppia. Kun pelaaja saavuttaa 90 pistettä, hän voi yrittää heittoa, joka tuottaa vähintään 9 pistettä.`,
      setup: `Pelaajat: 2-4 pelaajaa\nKomponentit:\n- Triple Freezer -areena\n- 3 akryylinoppaa\n- 3 puunoppaa`,
      throwing: `Jokainen pelaaja ravistaa noppia vähintään kahdesti ja sen jälkeen heittää nopat pelialueelle. Kaikki nopat on vapautettava ennen kuin käsi ylittää areenan reunan.`,
      scoring: `Pisteet määräytyvät noppien arvojen perusteella, jotka pysähtyvät pistealueelle. Vain ne nopat, jotka pysähtyvät ympyrän sisälle, lasketaan. Jos kaikki kolme noppaa pysähtyvät ympyrän sisälle, pisteet kaksinkertaistetaan.`,
      special: `Erikoistilanteet:\n1. Triplat - Kaikki 3 noppaa pysähtyvät pisteympyrän sisälle.\n2. Triple Freeze - Kaikki 3 noppaa osoittavat 6 ja pysähtyvät jäädytysvyöhykkeelle, mikä johtaa välittömään voittoon.\n3. Tripla-Triplat - Kaikki 3 noppaa osoittavat saman arvon, ja pelaaja saa 90 pistettä.`,
      winning: `Voittaakseen pelaajan on saavutettava vähintään 90 pistettä ja heitettävä viimeinen heitto, joka tuottaa vähintään 9 pistettä.`
    }
  }
};

const RulesSection: React.FC = () => {
  const [language, setLanguage] = useState<'english' | 'finnish'>('english');

  const handleLanguageChange = (lang: 'english' | 'finnish') => {
    setLanguage(lang);
  };

  return (
    <RuleContainer>
      <LanguageSwitch>
        <button onClick={() => handleLanguageChange('english')}>English</button>
        <button onClick={() => handleLanguageChange('finnish')}>Suomi</button>
      </LanguageSwitch>

      <Title>{rules[language].title}</Title>

      <SectionTitle>{rules[language].objective}</SectionTitle>
      <RuleText>{rules[language].content.objective}</RuleText>

      <SectionTitle>{rules[language].setup}</SectionTitle>
      <RuleText>{rules[language].content.setup}</RuleText>

      <SectionTitle>{rules[language].throwing}</SectionTitle>
      <RuleText>{rules[language].content.throwing}</RuleText>

      <SectionTitle>{rules[language].scoring}</SectionTitle>
      <RuleText>{rules[language].content.scoring}</RuleText>

      <SectionTitle>{rules[language].special}</SectionTitle>

      <SpecialSection>
        <SpecialIcon><FaAward /></SpecialIcon>
        <SpecialText>Triplat: {rules[language].content.special.split('\n')[1]}</SpecialText>
      </SpecialSection>
      <SpecialSection style={{ backgroundColor: '#2f7fbc' }}>
        <SpecialIcon><FaSnowflake /></SpecialIcon>
        <SpecialText>Triple Freeze: {rules[language].content.special.split('\n')[2]}</SpecialText>
      </SpecialSection>
      <SpecialSection style={{ backgroundColor: '#b58900' }}>
        <SpecialIcon><FaTrophy /></SpecialIcon>
        <SpecialText>Tripla-Triplat: {rules[language].content.special.split('\n')[3]}</SpecialText>
      </SpecialSection>

      <SectionTitle>{rules[language].winning}</SectionTitle>
      <RuleText>{rules[language].content.winning}</RuleText>
    </RuleContainer>
  );
};

export default RulesSection;
