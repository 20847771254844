import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Player } from '../types';  // Import the Player type

interface Props {
  winnerName?: string;
  players: Player[];
  clearGameState: () => void;
  startGame: (players: { id: string; name: string }[]) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing(2)};
`;

const WinnerText = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 42px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`;

const StatsList = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  width: 100%;
  max-width: 500px;
`;

const StatItem = styled.div<{ isWinner: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ isWinner, theme }) => (isWinner ? '#fff7d1' : theme.colors.surface)};
  border: 2px solid ${({ isWinner, theme }) => (isWinner ? '#ffd700' : theme.colors.primary)};
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ isWinner, theme }) => (isWinner ? '#333' : theme.colors.textPrimary)};
  flex-direction: column;
  font-weight: ${({ isWinner }) => (isWinner ? 'bold' : 'normal')};
  box-shadow: ${({ isWinner }) => (isWinner ? '0 0 20px rgba(255, 223, 0, 0.5)' : 'none')};
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }

  p {
    margin: 5px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  strong {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: ${({ theme }) => theme.spacing(3)};  
`;

const Button = styled.button`
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const AccuracyPercentage = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 10px;
  text-align: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const HistoryButton = styled.button`
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const HistoryContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
`;

const HistoryEntry = styled.p`
  font-size: 14px;
  margin: ${({ theme }) => theme.spacing(0.5)} 0;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: 'Roboto', sans-serif;
`;

const WinnerScreen: React.FC<Props> = ({ winnerName, players, clearGameState, startGame }) => {
  const navigate = useNavigate();
  const [showHistory, setShowHistory] = useState<{ [key: string]: boolean }>({});

  // Sort players to place the winner at the top
  const sortedPlayers = [...players].sort((a, b) => (a.name === winnerName ? -1 : b.name === winnerName ? 1 : 0));

    // Calculate maximum turn score for each player
    const getMaxTurnScore = (player: Player) => {
      return player.history.reduce((max, entry) => Math.max(max, entry.turnScore), 0);
    };

      // Calculate frozen dice count
  const getFrozenDiceCount = (player: Player) => {
    return player.history.reduce((count, entry) => count + entry.diceValues.filter((_, index) => entry.isFrozen[index]).length, 0);
  };

  const getWinType = (player: Player) => {
    if (player.name === winnerName) {
      return player.hasWonInstantly ? 'Instant Win' : 'Normal Win';
    }
    return 'Lost';
  };

// Calculate accuracy percentage based on complete turns
const getAccuracyPercentage = (player: Player) => {
  // Count the number of turns where at least one dice scored a value greater than 0
  const accurateTurns = player.history.filter(entry => entry.diceValues.some(value => value > 0)).length;

  // Calculate the total number of turns taken
  const totalTurns = player.history.length;

  // Calculate and return the accuracy percentage as a string
  return totalTurns > 0 ? ((accurateTurns / totalTurns) * 100).toFixed(2) : '0.00';
};

  // Count how many triples (all dice have a value)
  const getTriplesCount = (player: Player) => {
    return player.history.reduce((total, entry) => 
      total + (entry.diceValues.every(value => value > 0) ? 1 : 0), 0
    );
  };

  // Count how many triple triples (all dice have the same value between 222 and 555)
  const getTripleTriplesCount = (player: Player) => {
    return player.history.reduce((total, entry) => {
      const uniqueValues = Array.from(new Set(entry.diceValues)); // Replaced spread operator with Array.from
      return total + (uniqueValues.length === 1 && uniqueValues[0] >= 2 && uniqueValues[0] <= 5 ? 1 : 0);
    }, 0);
  };

  // Calculate the number of final throws
const getFinalThrowsCount = (player: Player) => {
  return player.history.filter(entry => entry.isFinalThrowAttempt).length;
};

  const toggleHistory = (playerId: string) => {
    setShowHistory((prevState) => ({
      ...prevState,
      [playerId]: !prevState[playerId],
    }));
  };

  const handleRestart = () => {
    clearGameState();
    startGame(players);
    navigate('/game');
  };

  const handleMainMenu = () => {
    clearGameState();
    navigate('/');
  };

  return (
    <Container>
      <WinnerText>🎉 {winnerName} Wins the Game! 🎉</WinnerText>

      {/* Display player stats */}
      <StatsList>
        {sortedPlayers.map((player) => (
          <StatItem key={player.id} isWinner={player.name === winnerName}>
            <div>
              <strong>{player.name}</strong>
              <p>Score: {player.score}</p>
              <p>Turns Taken: {player.history.length}</p>

              <p>Triples: {getTriplesCount(player)}</p>
              <p>Triple Triples: {getTripleTriplesCount(player)}</p>
              <p>Final Throws Taken: {getFinalThrowsCount(player)}</p>
              <p>Max Turn Score: {getMaxTurnScore(player)}</p>
              <p>Number of Frozen Dice: {getFrozenDiceCount(player)}</p>
              <p>Result: {getWinType(player)}</p>
              <AccuracyPercentage>
                Accuracy: {getAccuracyPercentage(player)}%
              </AccuracyPercentage>
              --placeholder--
            </div>
            <div>
              <HistoryButton onClick={() => toggleHistory(player.id)}>
                {showHistory[player.id] ? 'Hide History' : 'Show History'}
              </HistoryButton>
              {showHistory[player.id] && (
                <HistoryContainer>
                  {player.history.map((entry, index) => (
                    <HistoryEntry key={index}>
                      Turn {index + 1}: {entry.turnScore} points (Dice: {entry.diceValues.join(', ')})
                    </HistoryEntry>
                  ))}
                </HistoryContainer>
              )}
            </div>
          </StatItem>
        ))}
      </StatsList>

      <ButtonContainer>
        <Button onClick={handleRestart}>Play Again with Same Players</Button>
        <Button onClick={handleMainMenu}>Main Menu</Button>
      </ButtonContainer>
    </Container>
  );
};

export default WinnerScreen;
