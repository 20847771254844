import { Howl } from "howler";
import soundConfig from "../SoundConfig";

export const sounds = {
    TripleFreeze: new Howl({ src: [soundConfig.TripleFreeze] }),
    Points90: new Howl({ src: [soundConfig.Points90] }),
    TriplaTriplat: new Howl({ src: [soundConfig.TriplaTriplat] }),
    Freeze: new Howl({ src: [soundConfig.Freeze] }),
    Triplat: new Howl({ src: [soundConfig.Triplat] }),
    Ohi: new Howl({ src: [soundConfig.Ohi] }),
    Fumble: new Howl({ src: [soundConfig.Fumble] }),
    Rikki: new Howl({ src: [soundConfig.Rikki] }),
    StrongStart: new Howl({ src: [soundConfig.StrongStart] }),
    Score: new Howl({ src: [soundConfig.Score] }),

    // Special alternate sounds
    Special1: new Howl({ src: [soundConfig.Special1] }),
    Special12: new Howl({ src: [soundConfig.Special12] }),
    Special24: new Howl({ src: [soundConfig.Special24] }),
    Special2Dice: new Howl({ src: [soundConfig.Special2Dice] }),

    // Tie game sound
    TieGame: new Howl({ src: [soundConfig.TieGame] }),

    // Sounds for each score 0-34
    Points0: new Howl({ src: [soundConfig.Points0] }),
    Points1: new Howl({ src: [soundConfig.Points1] }),
    Points2: new Howl({ src: [soundConfig.Points2] }),
    Points3: new Howl({ src: [soundConfig.points3] }),
    Points4: new Howl({ src: [soundConfig.points4] }),
    Points5: new Howl({ src: [soundConfig.points5] }),
    Points6: new Howl({ src: [soundConfig.points6] }),
    Points7: new Howl({ src: [soundConfig.points7] }),
    Points8: new Howl({ src: [soundConfig.points8] }),
    Points9: new Howl({ src: [soundConfig.points9] }),
    Points10: new Howl({ src: [soundConfig.points10] }),
    Points11: new Howl({ src: [soundConfig.points11] }),
    Points12: new Howl({ src: [soundConfig.points12] }),
    Points13: new Howl({ src: [soundConfig.points13] }),
    Points14: new Howl({ src: [soundConfig.points14] }),
    Points15: new Howl({ src: [soundConfig.points15] }),
    Points16: new Howl({ src: [soundConfig.points16] }),
    Points17: new Howl({ src: [soundConfig.points17] }),
    Points18: new Howl({ src: [soundConfig.points18] }),
    Points19: new Howl({ src: [soundConfig.points19] }),
    Points20: new Howl({ src: [soundConfig.points20] }),
    Points21: new Howl({ src: [soundConfig.points21] }),
    Points22: new Howl({ src: [soundConfig.points22] }),
    Points23: new Howl({ src: [soundConfig.points23] }),
    Points24: new Howl({ src: [soundConfig.points24] }),
    Points26: new Howl({ src: [soundConfig.points26] }),
    Points28: new Howl({ src: [soundConfig.points28] }),
    Points30: new Howl({ src: [soundConfig.points30] }),
    Points32: new Howl({ src: [soundConfig.points32] }),
    Points34: new Howl({ src: [soundConfig.Points34] }),

    //Point sounds for Playercard
    HÄVIÖ: new Howl({ src: [soundConfig.HÄVIÖ] }),
    MAX_POINTS: new Howl({ src: [soundConfig.MAX_POINTS] }),
    OHI: new Howl({ src: [soundConfig.OHI] }),
    OSUTTRIPLAT: new Howl({ src: [soundConfig.OSUTTRIPLAT] }),
    PISTE_1TO3: new Howl({ src: [soundConfig.PISTE_1TO3] }),
    PISTE_4TO7: new Howl({ src: [soundConfig.PISTE_4TO7] }),
    PISTE_8TO11: new Howl({ src: [soundConfig.PISTE_8TO11] }),
    PISTE_12TO15: new Howl({ src: [soundConfig.PISTE_12TO15] }),
    PISTE_16TO19: new Howl({ src: [soundConfig.PISTE_16TO19] }),
    PISTE_20TO23: new Howl({ src: [soundConfig.PISTE_20TO23] }),
    PISTE_24TO27: new Howl({ src: [soundConfig.PISTE_24TO27] }),
    PISTE_28TO29: new Howl({ src: [soundConfig.PISTE_28TO29] }),
    PISTE_30TO31: new Howl({ src: [soundConfig.PISTE_30TO31] }),
    VOITTOTRIPLATTRIPLAT: new Howl({ src: [soundConfig.VOITTOTRIPLATTRIPLAT] }),
};

export const playSound = (soundKey: keyof typeof sounds, delay: number = 0) => {
    resumeAudioContext(); // Ensure the context is resumed before playing the sound

    if (delay > 0) {
        setTimeout(() => {
            sounds[soundKey].play();
        }, delay);
    } else {
        sounds[soundKey].play();
    }
};

export const stopSound = (soundKey: keyof typeof sounds) => {
    sounds[soundKey].stop();
};

// Helper function to resume the AudioContext
export const resumeAudioContext = () => {
    if (Howler.ctx && Howler.ctx.state === "suspended") {
        Howler.ctx.resume().then(() => {
            console.log("AudioContext resumed");
        }).catch(err => {
            console.error("Error resuming AudioContext:", err);
        });
    }
};
