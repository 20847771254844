import { playSound } from './soundUtil';
import { sounds } from './soundUtil';

// Global debounce state
let isDebouncingFreeze = false;
let freezeDebounceTimeout: NodeJS.Timeout | null = null;

export const playFreezeSound = () => {
  if (!isDebouncingFreeze) {
    playSound('Freeze'); // Play the freeze sound
    isDebouncingFreeze = true;

    // Reset the debounce state after 1 second (or adjust as needed)
    freezeDebounceTimeout = setTimeout(() => {
      isDebouncingFreeze = false;
    }, 1000); // 1000ms debounce period
  }
};

// Clear the debounce timer (if needed for unmounting or other cleanup)
export const clearFreezeDebounce = () => {
  if (freezeDebounceTimeout) {
    clearTimeout(freezeDebounceTimeout);
    freezeDebounceTimeout = null;
    isDebouncingFreeze = false;
  }
};

// Function to map score to the correct sound key
export const getPointsSoundKey = (score: number): keyof typeof sounds | null => {
  if (score >= 0 && score <= 34) {
    return `Points${score}` as keyof typeof sounds;
  }
  return null; // Return null if score does not match a sound key
};

interface SoundConditions {
  key: keyof typeof sounds;
  condition: boolean;
  priority: number;
}

// Tie game logic is commented out for now
// let tieAnnounced = false; // Track whether a tie has been announced during the game

let lastSpecial1 = false;
let lastSpecial12 = false;
let lastSpecial24 = false;
let lastSpecialOhi = false;

export const handleTurnSounds = (
  score: number, 
  currentPlayer: any, 
  outcome: string, 
  allDiceFrozenWithSix: boolean,
  soundEnabled: boolean 
) => {
  if (!soundEnabled) return; // If sound is disabled, exit the function early
  
  const scoreSoundKey = getPointsSoundKey(score);
  const anyDiceFrozen = currentPlayer.dice.some((die: any) => die.isFrozen); // Check if any dice are frozen


  // First turn: Check for StrongStart or normal score sound
  if (currentPlayer.history.length === 0) {
    if (score >= 10) {
      // Play StrongStart if the score is 10 or more
      playSound('StrongStart', 500); // 200ms delay as an example
      return; // Block other sounds on strong start
    } else {
      // Play normal score sound if it's the first turn and score is less than 10
      if (scoreSoundKey) {
        playSound(scoreSoundKey, 800);
      }
      return; // Ensure no other sounds play
    }
  }

  // Other sounds after the first turn
  const soundConditions: SoundConditions[] = [
    { key: 'TripleFreeze', condition: allDiceFrozenWithSix, priority: 1 },
    { key: 'TriplaTriplat', condition: score === 90 && !anyDiceFrozen, priority: 2 }, // Only play Triplat if no dice are frozen
    { key: 'Special2Dice', 
      condition: currentPlayer.dice.filter((die: any) => die.value === 1).length === 2 
                && currentPlayer.dice.filter((die: any) => die.value === 0).length === 1 
                && score === 2, 
      priority: 3 
    },
    { key: 'Ohi', condition: score === 0 && !lastSpecialOhi, priority: 4 },
    { key: 'Fumble', condition: score === 0 && lastSpecialOhi, priority: 4 },
    { key: 'Triplat', condition: !anyDiceFrozen && currentPlayer.dice.every((die: any) => die.value > 0), priority: 5 }, // Ensure no frozen dice for Triplat

    // Regular score sounds should play as long as there is a valid score sound
    scoreSoundKey ? { key: scoreSoundKey, condition: score >= 0 && score <= 34, priority: 6 } : null,
  
  ].filter((condition): condition is SoundConditions => condition !== null);  // Filter out null conditions
  
  // Get the highest priority sound condition that matches
  const matchedSound = soundConditions
    .filter((condition) => condition.condition)
    .sort((a, b) => a.priority - b.priority)[0];

  // Play the matched sound
  if (matchedSound && matchedSound.key in sounds) {
    playSound(matchedSound.key);

    // Track last alternate sounds
    if (score === 1) lastSpecial1 = !lastSpecial1;
    if (score === 12) lastSpecial12 = !lastSpecial12;
    if (score === 24) lastSpecial24 = !lastSpecial24;
    if (score === 0) lastSpecialOhi = !lastSpecialOhi;
  }
};

// Tie game helper function is commented out for now
/*
export const checkTieGame = (players: any[]) => {
    if (players.length < 2) {
      // If there's only one player, a tie game isn't possible
      return false;
    }

    const firstPlayerScore = players[0]?.score || 0;
    return players.every(player => player.score === the firstPlayerScore && player score > 0);
};
*/
