import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px;

  &:hover {
    opacity: 0.9;
  }
`;

const ConfirmButton = styled(Button)`
  background-color: green;
  color: white;
`;

const CancelButton = styled(Button)`
  background-color: red;
  color: white;
`;

const ConfirmationModal = ({ onConfirm, onCancel }: { onConfirm: () => void; onCancel: () => void }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <h3>Are you sure you want to undo the last turn?</h3>
        <ConfirmButton onClick={onConfirm}>Yes, Undo</ConfirmButton>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ConfirmationModal;
