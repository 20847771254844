export const lightTheme = {
  colors: {
    primary: '#007bff',
    primaryDark: '#0056b3',
    background: '#f5f5f5',
    surface: '#ffffff',
    textPrimary: '#333333',
    textSecondary: '#888888',
  },
  spacing: (factor) => `${factor * 8}px`,
};

export const darkTheme = {
  colors: {
    primary: '#9b59b6',
    primaryDark: '#8e44ad',
    background: '#333333',
    surface: '#444444',
    textPrimary: '#f5f5f5',
    textSecondary: '#cccccc',
  },
  spacing: (factor) => `${factor * 8}px`,
};
