// components/BuyGame.tsx
import React from 'react';

const BuyGame: React.FC = () => {
  return (
    <div>
      <h1>Buy the Game</h1>
      <p>Information about purchasing the game...</p>
    </div>
  );
};

export default BuyGame;
