// Signup.tsx
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { logSignUpEvent } from '../firebaseConfig';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const Container = styled.div`
  max-width: 400px;
  margin: 80px auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.surface};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-sizing: border-box;  /* Ensure padding is included in width calculation */

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
  }
`;

const PasswordToggleIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Button = styled.button`
  padding: 10px;
  background: linear-gradient(45deg, ${({ theme }) => theme.colors.primary}, ${({ theme }) => theme.colors.primaryDark});
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  width: 100%;

  &:hover {
    background: linear-gradient(45deg, ${({ theme }) => theme.colors.primaryDark}, ${({ theme }) => theme.colors.primary});
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3); /* Focus ring */
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
`;

const LoginLink = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 0.9rem;

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const PasswordStrengthText = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: -10px;
`;

const Signup: React.FC = () => {
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const calculatePasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;
    return strength;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pwd = e.target.value;
    setPassword(pwd);
    setPasswordStrength(calculatePasswordStrength(pwd));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await signup(name, email, password);
      logSignUpEvent(); // Log signup event in Firebase Analytics
      navigate('/profile'); // Redirect to profile after signup
    } catch (error) {
      console.error('Signup error:', error);
      setError('Failed to create an account. Please try again.');
    }
  };

  return (
    <Container>
      <Title>Create an Account</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <InputWrapper>
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <PasswordToggleIcon onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </PasswordToggleIcon>
        </InputWrapper>
        {password && (
          <PasswordStrengthText style={{ color: passwordStrength >= 4 ? 'green' : passwordStrength >= 2 ? 'orange' : 'red' }}>
            {passwordStrength >= 4
              ? 'Strong password'
              : passwordStrength >= 2
              ? 'Medium password'
              : 'Weak password'}
          </PasswordStrengthText>
        )}
        <InputWrapper>
          <Input
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <PasswordToggleIcon onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
            {showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </PasswordToggleIcon>
        </InputWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Button type="submit">Create Account</Button>
      </Form>
      <LoginLink>
        Already have an account? <Link to="/login">Log in here</Link>.
      </LoginLink>
    </Container>
  );
};

export default Signup;
