import styled from 'styled-components';
import { motion } from 'framer-motion';

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const SwitchLabel = styled.span`
  font-size: 16px;
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const Switch = styled(motion.div)`
  width: 50px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  border-radius: 12px;
  position: relative;
  cursor: pointer;
`;

const SwitchToggle = styled(motion.div)`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
`;

interface SoundSwitcherProps {
  soundEnabled: boolean;
  setSoundEnabled: (enabled: boolean) => void;
}

const SoundSwitcher: React.FC<SoundSwitcherProps> = ({ soundEnabled, setSoundEnabled }) => {
  const toggleSound = () => {
    setSoundEnabled(!soundEnabled);
  };

  return (
    <SwitchContainer>
      <SwitchLabel>{soundEnabled ? 'Sound On' : 'Sound Off'}</SwitchLabel>
      <Switch onClick={toggleSound}>
        <SwitchToggle
          animate={{ x: soundEnabled ? 26 : 0 }}
          transition={{ type: 'spring', stiffness: 300 }}
        />
      </Switch>
    </SwitchContainer>
  );
};

export default SoundSwitcher;
