import React, { useEffect, useState, useContext } from 'react';
import { ref, get, set, remove } from 'firebase/database';
import { database } from '../firebaseConfig';
import styled from 'styled-components';
import { AuthContext } from '../AuthContext';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import icons for the toggle arrow

// Styles for the page and cards
const EventsWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
`;

const EventCard = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const EventTitle = styled.h2`
  margin-top: 0;
  font-size: 24px;
  font-weight: 600;
  color: #007BFF;
`;

const EventDate = styled.p`
  color: #555;
  font-size: 14px;
  margin: 5px 0;
`;

const EventDescription = styled.div`
  line-height: 1.6;
  font-size: 16px;
  color: #333;

  h1, h2, h3, h4 {
    font-weight: bold;
  }

  strong {
    font-weight: bold;
  }
`;

const EventImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
`;

const JoinButton = styled.button`
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #c82333;
  }
`;

const ParticipantsList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const ParticipantItem = styled.li`
  font-size: 14px;
  color: #333;
  margin-right: 10px;
  margin-bottom: 5px;
`;

const ToggleButton = styled.button`
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #0056b3; // Darker blue on hover
  }

  svg {
    margin-left: 6px;
  }
`;

const NoParticipants = styled.p`
  color: #888;
  font-size: 14px;
`;

const Events: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({}); // Track expanded state for each event

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventRef = ref(database, 'events');
        const snapshot = await get(eventRef);
        
        if (snapshot.exists()) {
          const eventsData = Object.entries(snapshot.val()).map(([id, data]) => {
            const eventData = data as { [key: string]: any };
            const participants = eventData.participants ? Object.values(eventData.participants) : [];
            return {
              id, // Store the event ID
              ...eventData,
              participants, // Store participants as an array
            };
          });
          setEvents(eventsData);
        } else {
          setEvents([]); // No events available
        }
      } catch (error) {
        setError('Error fetching events'); // Set error message
        console.error(error);
      } finally {
        setLoading(false); // Stop loading once data is fetched or an error occurs
      }
    };

    fetchEvents();
  }, []);

  const joinEvent = async (eventId: string) => {
    if (!user) {
      alert('You must be logged in to join an event');
      return;
    }

    try {
      const eventRef = ref(database, `events/${eventId}/participants/${user.uid}`);
      await set(eventRef, { name: user.name }); // Add user to the participants list
      alert('You have joined the event!');
    } catch (error) {
      console.error('Error joining event:', error);
    }
  };

  const deleteEvent = async (eventId: string) => {
    if (!user || user.role !== 'admin') {
      alert('You are not authorized to delete this event');
      return;
    }

    try {
      const eventRef = ref(database, `events/${eventId}`);
      await remove(eventRef); // Remove the event from Firebase
      setEvents(events.filter((event) => event.id !== eventId)); // Update the local state
      alert('Event deleted successfully');
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const toggleParticipants = (eventId: string) => {
    setExpanded((prev) => ({ ...prev, [eventId]: !prev[eventId] })); // Toggle the expanded state for the event
  };

  if (loading) {
    return <p>Loading events...</p>;
  }

  if (error) {
    return <p>{error}</p>; // Display error if any
  }

  return (
    <EventsWrapper>
      <h1>Upcoming Events</h1>
      {events.length > 0 ? (
        events.map((event) => (
          <EventCard key={event.id}>
            {event.imageUrl && <EventImage src={event.imageUrl} alt={event.title} />}
            <EventTitle>{event.title}</EventTitle>
            <EventDate>{event.date}</EventDate>
            <EventDescription dangerouslySetInnerHTML={{ __html: event.description }} />
            <p><strong>Max Participants:</strong> {event.maxParticipants}</p>
            
            <ToggleButton onClick={() => toggleParticipants(event.id)}>
              {expanded[event.id] ? (
                <>
                  Hide Participants <FaChevronUp />
                </>
              ) : (
                <>
                  Show Participants <FaChevronDown />
                </>
              )}
            </ToggleButton>

            {expanded[event.id] && (
              <ParticipantsList>
                {event.participants.length > 0 ? (
                  event.participants.map((participant: any, index: number) => (
                    <ParticipantItem key={index}>{participant.name}</ParticipantItem>
                  ))
                ) : (
                  <NoParticipants>No participants yet</NoParticipants>
                )}
              </ParticipantsList>
            )}
            
            <JoinButton onClick={() => joinEvent(event.id)}>
              Join Event
            </JoinButton>

            {user?.role === 'admin' && (
              <DeleteButton onClick={() => deleteEvent(event.id)}>Delete Event</DeleteButton>
            )}
          </EventCard>
        ))
      ) : (
        <p>No upcoming events at the moment.</p>
      )}
    </EventsWrapper>
  );
};

export default Events;
