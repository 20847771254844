import React from 'react';
import styled from 'styled-components';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { playFreezeSound } from '../Utils/soundHandler';

interface Props {
  value: number;
  isFrozen: boolean;
  onToggleFreeze: () => void;
  onChange: (value: number) => void;
  soundEnabled: boolean;  // Add soundEnabled as a prop
}

const DiceContainer = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => prop !== 'isFrozen',
})<{ isFrozen: boolean }>`
  width: 60px;
  height: 60px;
  background-color: ${({ isFrozen, theme }) =>
    isFrozen ? theme.colors.secondary : theme.colors.surface};
  color: ${({ isFrozen, theme }) =>
    isFrozen ? theme.colors.textPrimary : theme.colors.textSecondary};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 8px 0;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 28px;
  cursor: pointer;
  padding: 0;
  transition: color 0.2s ease;

  &:disabled {
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    transform: scale(1.1);
  }
`;

const DiceValue = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const DiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Dice: React.FC<Props> = ({ value, isFrozen, onToggleFreeze, onChange, soundEnabled  }) => {

  const increment = () => {
    onChange((value + 1) % 7); // Loop back to 0 after reaching 6
  };

  const decrement = () => {
    onChange(value === 0 ? 6 : value - 1); // Loop back to 6 from 0
  };

  const handleToggleFreeze = () => {
    if (!isFrozen) {
      onChange(6); // Automatically set to 6 when freezing

      // Only play the freeze sound if soundEnabled is true
      if (soundEnabled) {
        playFreezeSound(); // Call the globally debounced freeze sound
      }
    }
    onToggleFreeze();
  };

  return (
    <DiceWrapper>
      <ArrowButton onClick={increment}>
        <FaChevronUp />
      </ArrowButton>
      <DiceContainer
        isFrozen={isFrozen}
        onClick={handleToggleFreeze}
        animate={{
          rotate: isFrozen ? 360 : 0,
          scale: isFrozen ? 1.1 : 1,
        }}
        transition={{ duration: 0.5 }}
      >
        <DiceValue>{value}</DiceValue>
      </DiceContainer>
      <ArrowButton onClick={decrement}>
        <FaChevronDown />
      </ArrowButton>
    </DiceWrapper>
  );
};

export default Dice;
