import React, { useState, useContext } from 'react';
import styled, { ThemeContext, DefaultTheme } from 'styled-components';
import { motion } from 'framer-motion';
import { lightTheme, darkTheme } from '../styles/themes';

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const SwitchLabel = styled.span`
  font-size: 16px;
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const Switch = styled(motion.div)`
  width: 50px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  border-radius: 12px;
  position: relative;
  cursor: pointer;
`;

const SwitchToggle = styled(motion.div)`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
`;

const ThemeSwitcher: React.FC = () => {
  const themeContext = useContext(ThemeContext) as DefaultTheme;
  const [isDarkMode, setIsDarkMode] = useState(themeContext === darkTheme);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    themeContext.setTheme(isDarkMode ? lightTheme : darkTheme);
  };

  return (
    <SwitchContainer>
      <SwitchLabel>{isDarkMode ? 'Dark' : 'Light'} Mode</SwitchLabel>
      <Switch onClick={toggleTheme}>
        <SwitchToggle
          animate={{ x: isDarkMode ? 26 : 0 }}
          transition={{ type: 'spring', stiffness: 300 }}
        />
      </Switch>
    </SwitchContainer>
  );
};

export default ThemeSwitcher;
