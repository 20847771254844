export interface DiceState {
  value: number;
  isFrozen: boolean;
}

export const calculateDiceSum = (dice: DiceState[]): number => {
  return dice.reduce((sum, die) => sum + die.value, 0);
};

// Function to check for "Triplat"
export const checkForTriplat = (dice: DiceState[]): number | null => {
  const values = dice.map(die => die.value);
  const allInScoringRange = values.every(value => value >= 1 && value <= 6);
  const anyFrozen = dice.some(die => die.isFrozen); // Check if any dice are frozen

  // Triplat only applies if no dice are frozen
  if (allInScoringRange && !anyFrozen) {
    return calculateDiceSum(dice) * 2;
  }

  return null;
};

// Function to check for "Tripla-Triplat"
export const checkForTriplaTriplat = (dice: DiceState[]): { score: number, outcome: 'win' | 'lose' | 'continue' } | null => {
  const values = dice.map(die => die.value);
  const allInScoringRange = values.every(value => value >= 1 && value <= 6);
  const allIdentical = values.every(value => value === values[0]);
  
  if (allInScoringRange && allIdentical) {
    if (values[0] === 6) {
      // Check if all dice are unfrozen or if all are frozen
      const allUnfrozen = dice.every(die => !die.isFrozen);
      const allFrozen = dice.every(die => die.isFrozen);

      if (allUnfrozen || allFrozen) {
        return { score: 90, outcome: 'win' }; // Automatic win if values are 6, 6, 6 and all dice are either unfrozen or frozen
      }
    } else if (values[0] === 1) {
      return { score: 0, outcome: 'lose' }; // Lose all points if values are 1, 1, 1
    } else {
      return { score: 90, outcome: 'continue' }; // Score 90 and continue if all identical values
    }
  }

  return null;
};


// Updated Function to check for "Freeze" (frozen dice, especially with value of 6)
export const checkForFreeze = (dice: DiceState[]): { score: number, outcome: 'freeze' } | null => {
  let totalScore = 0;
  let freezeDetected = false;

  // Split dice into frozen and unfrozen
  const frozenDice = dice.filter(die => die.isFrozen);
  const nonFrozenDice = dice.filter(die => !die.isFrozen && die.value > 0);

  // Check if at least one frozen die exists (trigger freeze outcome regardless of score doubling)
  if (frozenDice.length > 0) {
    freezeDetected = true;

    
  }

  if (frozenDice.length === 1 && nonFrozenDice.length === 0) {
    totalScore = 0;
  } else {
  frozenDice.forEach((die) => {
    // Apply score doubling only if frozen die has value 6 and there are 2 non-frozen dice
    if (die.value === 6 && nonFrozenDice.length === 2) {
      totalScore += 6 * 2;
    } else {
      totalScore += die.value; // Add frozen value without doubling
    }
  });

  nonFrozenDice.forEach((die) => {
    totalScore += die.value; // Add non-frozen dice values
  });
  }
  if (freezeDetected) {
    return { score: totalScore, outcome: 'freeze' }; // Outcome is freeze regardless of doubling
  }

  return null;
};

// Function to check for "Miss" (all dice have value 0)
export const checkForMiss = (dice: DiceState[]): { score: number, outcome: 'miss' } | null => {
  const allZeros = dice.every(die => die.value === 0);

  if (allZeros) {
    return { score: 0, outcome: 'miss' }; // If all dice are 0, it's a miss
  }

  return null;
};

// Function to apply the dice rules
export const applyDiceRules = (dice: DiceState[]): { score: number, outcome: 'win' | 'lose' | 'continue' | 'freeze' | 'miss' } => {
  // First, check for Tripla-Triplat (highest priority)
  const triplaTriplatResult = checkForTriplaTriplat(dice);
  if (triplaTriplatResult) {
    return triplaTriplatResult;
  }

  // Then, check for Miss (if all dice are 0)
  const missResult = checkForMiss(dice);
  if (missResult) {
    return missResult;
  }

  // Then, check for Freeze (must be called before checking Triplat)
  const freezeResult = checkForFreeze(dice);
  if (freezeResult) {
    return freezeResult; // Freeze should override Triplat and other outcomes
  }

  // Then, check for Triplat (only if no dice are frozen)
  const triplatResult = checkForTriplat(dice);
  if (triplatResult !== null) {
    return { score: triplatResult, outcome: 'continue' };
  }

  // Default to the regular sum of dice
  return { score: calculateDiceSum(dice), outcome: 'continue' };
};

// Keep these as they are for the final throw logic
export const checkForFinalThrow = (score: number): boolean => {
  return score >= 90;
};

export const checkWinningThrow = (diceSum: number): boolean => {
  return diceSum >= 9;
};
