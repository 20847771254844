import React, { useState } from 'react';
import { createRoot } from 'react-dom/client'; // Updated import
import App from './App';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from './styles/themes';
import { GlobalStyles } from './styles/GlobalStyles';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './AuthContext';

const Index = () => {
  const [theme, setTheme] = useState(lightTheme);

  return (
    <React.StrictMode>
      <ThemeProvider theme={{ ...theme, setTheme }}>
        <GlobalStyles />
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

// Updated rendering logic using createRoot
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!); // Ensure rootElement is not null
root.render(<Index />);
