const soundConfig = {
    TripleFreeze: '/sounds/Triple_Freezer.wav',
    Points90: './sounds/90_points.wav',
    TriplaTriplat: './sounds/Tripla_Triplat.wav',
    Freeze: './sounds/Freeze.wav',
    Triplat: './sounds/Triplat.wav',
    Ohi: './sounds/Ohi.wav',    // alternet for fumble and points 0
    Fumble: './sounds/Fumble.wav',  // alternet for ohi and points 0
    Rikki: './sounds/Rikki_voice.wav',    //when player breaks 90 points
    StrongStart: './sounds/Vahva_aloitus.wav',  //when player gets 10 or more points in first throw
    TieGame: './sounds/Tasapeli.wav',  //when game reaches a tie
    Score: './sounds/point_sound.mp3',  //when players score updates
  
    // Special alternate sounds
    Special1: './sounds/Pablo.wav',
    Special12: './sounds/Half_bauer.wav',
    Special24: './sounds/Jack_Bauer.wav',
    Special2Dice: './sounds/Pablon_silmät.wav',
  
    // Sounds for each score 0-34
    Points0: './sounds/0_points.wav',
    Points1: './sounds/1_points.wav',
    Points2: './sounds/2_points.wav',
    points3: './sounds/3_points.wav',
    points4: './sounds/4_points.wav',
    points5: './sounds/5_points.wav',
    points6: './sounds/6_points.wav',
    points7: './sounds/7_points.wav',
    points8: './sounds/8_points.wav',
    points9: './sounds/9_points.wav',
    points10: './sounds/10_points.wav',
    points11: './sounds/11_points.wav',
    points12: './sounds/12_points.wav',
    points13: './sounds/13_points.wav',
    points14: './sounds/14_points.wav',
    points15: './sounds/15_points.wav',
    points16: './sounds/16_points.wav',
    points17: './sounds/17_points.wav',
    points18: './sounds/18_points.wav',
    points19: './sounds/19_points.wav',
    points20: './sounds/20_points.wav',
    points21: './sounds/21_points.wav',
    points22: './sounds/22_points.wav',
    points23: './sounds/23_points.wav',
    points24: './sounds/24_points.wav',
    points26: './sounds/26_points.wav',
    points28: './sounds/28_points.wav',
    points30: './sounds/30_points.wav',
    points32: './sounds/32_points.wav',
    Points34: './sounds/34_points.wav',
  
    //Point sounds for Playercard
    HÄVIÖ: './sounds/points/HÄVIÖ_1.wav',
    MAX_POINTS: './sounds/points/MAX_POINTS_1.wav',
    OHI: './sounds/points/OHI_1.wav',
    OSUTTRIPLAT: './sounds/points/OSUTTRIPLAT.wav',
    PISTE_1TO3: './sounds/points/PISTE_1_3_1.wav',
    PISTE_4TO7: './sounds/points/PISTE_4_7_1.wav',
    PISTE_8TO11: './sounds/points/PISTE_8_11_1.wav',
    PISTE_12TO15: './sounds/points/PISTE_12_15_1.wav',
    PISTE_16TO19: './sounds/points/PISTE_16_19_1.wav',
    PISTE_20TO23: './sounds/points/PISTE_20_23_1.wav',
    PISTE_24TO27: './sounds/points/PISTE_24_27_1.wav',
    PISTE_28TO29: './sounds/points/PISTE_28_29_1.wav',
    PISTE_30TO31: './sounds/points/PISTE_30_31_1.wav',
    VOITTOTRIPLATTRIPLAT: './sounds/points/VOITTOTRIPLATTRIPLAT.wav',

};

export default soundConfig;