// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA22ZkMcctNBu4I0EZkRcHlNDwvRG6LLAQ",
  authDomain: "triple-freezer.firebaseapp.com",
  databaseURL: 'https://triple-freezer-default-rtdb.europe-west1.firebasedatabase.app/',
  projectId: "triple-freezer",
  storageBucket: "triple-freezer.appspot.com",
  messagingSenderId: "471051501479",
  appId: "1:471051501479:web:9b6c5f59cc90b20093c082",
  measurementId: "G-0MLY49W085"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Export Firebase services
export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app);

// Event logging functions
export const logSignUpEvent = () => {
  logEvent(analytics, 'sign_up', {
    method: 'Email',
  });
};

export const logLoginEvent = () => {
  logEvent(analytics, 'login', {
    method: 'Email',
  });
};

export const logGamePlayedEvent = (gameName: string) => {
  logEvent(analytics, 'game_played', {
    game_name: gameName,
  });
};

export const logDiceCalculatedEvent = (gameName: string, diceValue: number) => {
  logEvent(analytics, 'dice_calculated', {
    game_name: gameName,
    dice_value: diceValue,
  });
};

export const logGameFinishedEvent = (gameName: string, winner: string, finalScore: number) => {
  logEvent(analytics, 'game_finished', {
    game_name: gameName,
    winner: winner,
    final_score: finalScore,
  });
};

export const logTripleFreezeEvent = (playerName: string) => {
  logEvent(analytics, 'triple_freeze', {
    player_name: playerName,
  });
};

export const logLostAllPointsEvent = (playerName: string) => {
  logEvent(analytics, 'lost_all_points', {
    player_name: playerName,
  });
};

export const logTriplaTriplatEvent = (playerName: string) => {
  logEvent(analytics, 'tripla_triplat', {
    player_name: playerName,
  });
};

export const logTriplatEvent = (playerName: string) => {
  logEvent(analytics, 'triplat', {
    player_name: playerName,
  });
};