import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill'; // Import the rich text editor
import 'react-quill/dist/quill.snow.css'; // Import the styles
import styled from 'styled-components';

const AdminPageWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const AdminOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const OptionButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const CreatePostForm = styled.form<{ isVisible: boolean }>`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
`;

const FormTextArea = styled(ReactQuill)`
  .ql-editor {
    min-height: 150px;
    font-size: 14px;
  }
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #218838;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const AdminPage: React.FC = () => {
  const { user, postEvent } = useContext(AuthContext);
  const [title, setTitle] = useState(''); // Rich text for the title
  const [description, setDescription] = useState(''); // Rich text for the description
  const [date, setDate] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [maxParticipants, setMaxParticipants] = useState(0);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [showCreatePost, setShowCreatePost] = useState(false); // Toggle for showing create post form
  const navigate = useNavigate();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (user && user.role === 'admin') {
      try {
        await postEvent({
          title, // Now supports rich text
          description, // Save the HTML description
          date,
          imageUrl,
          imageFile: imageFile || undefined, // Convert null to undefined
          maxParticipants,
        });
        navigate('/events');
      } catch (error) {
        console.error(error);
        alert('Error posting event');
      }
    } else {
      alert('Unauthorized: You must be an admin to post an event');
    }
  };

  return (
    <AdminPageWrapper>
      <h1>Admin Panel</h1>

      {/* Admin Options */}
      <AdminOptions>
        <OptionButton onClick={() => setShowCreatePost((prev) => !prev)}>
          {showCreatePost ? 'Hide Create Post' : 'Create Post'}
        </OptionButton>
        <OptionButton disabled>Create Tournament Bracket (Coming Soon)</OptionButton>
        <OptionButton disabled>See Users (Coming Soon)</OptionButton>
      </AdminOptions>

      {/* Toggleable Create Post Form */}
      <CreatePostForm isVisible={showCreatePost} onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>Title (Rich Text):</FormLabel>
          <FormTextArea value={title} onChange={setTitle} />
        </FormGroup>

        <FormGroup>
          <FormLabel>Description:</FormLabel>
          <FormTextArea value={description} onChange={setDescription} />
        </FormGroup>

        <FormGroup>
          <FormLabel>Date:</FormLabel>
          <FormInput
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Image URL (optional):</FormLabel>
          <FormInput
            type="text"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Or Upload Image:</FormLabel>
          <FormInput type="file" accept="image/*" onChange={handleFileChange} />
        </FormGroup>

        <FormGroup>
          <FormLabel>Max Participants:</FormLabel>
          <FormInput
            type="number"
            value={maxParticipants}
            onChange={(e) => setMaxParticipants(parseInt(e.target.value))}
            required
          />
        </FormGroup>

        <SubmitButton type="submit">Post Event</SubmitButton>
      </CreatePostForm>
    </AdminPageWrapper>
  );
};

export default AdminPage;
