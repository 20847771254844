import React, { useContext, useEffect, useState } from 'react';
import { Pie, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement
} from 'chart.js'; // Import required elements from chart.js
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';

// Register the elements required for Pie and Line charts
ChartJS.register(ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

// Styled components for modern and responsive design
const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: ${({ theme }) => theme.spacing(4)};
  max-width: 600px;
  margin: ${({ theme }) => theme.spacing(6)} auto;
  text-align: center;
`;

const Header = styled.h2`
  font-size: 28px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.colors.primary};
`;

const Info = styled.p`
  font-size: 18px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const SpecialThrowsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)};
  border-top: 1px solid ${({ theme }) => theme.colors.textSecondary};
  padding-top: ${({ theme }) => theme.spacing(4)};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: ${({ theme }) => theme.spacing(2)};
`;

const SpecialThrowsHeader = styled.h3`
  grid-column: 1 / -1;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const SpecialThrow = styled.div`
  font-size: 16px;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ChartContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(4)} auto;
  max-width: 500px;
`;

const LogoutButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border: none;
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(6)};
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  transition: background-color 0.3s ease;
  margin-top: ${({ theme }) => theme.spacing(4)};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const Profile: React.FC = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [accuracyData, setAccuracyData] = useState<any[]>([]); // State to store accuracy data

  useEffect(() => {
    // Fetch accuracy data from Firebase
    if (user) {
      const accuracyRef = ref(database, `users/${user.uid}/accuracyData`);
      onValue(accuracyRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const accuracyArr = Object.values(data).map((turn: any) => ({
            accuracyRate: (turn.hitMiss.filter(Boolean).length / turn.hitMiss.length) * 100,
            timestamp: turn.timestamp,
          }));
          setAccuracyData(accuracyArr);
        }
      });
    }
  }, [user]);

  if (!user) {
    return <p>Loading...</p>;
  }

  const handleLogout = async () => {
    await logout();
    navigate('/'); // Redirect to the Start Screen after logout
  };

  const calculateWinPercentage = () => {
    if (user.stats.gamesPlayed === 0) {
      return 0;
    }
    return ((user.stats.gamesWon / user.stats.gamesPlayed) * 100).toFixed(2);
  };

  const calculateAverageAccuracy = () => {
    if (accuracyData.length === 0) return 0;
    const totalAccuracy = accuracyData.reduce((sum, turn) => sum + turn.accuracyRate, 0);
    return (totalAccuracy / accuracyData.length).toFixed(2);
  };

  // Chart data configuration for win/loss ratio
  const winPercentage = calculateWinPercentage();
  const chartData = {
    labels: ['Wins', 'Losses'],
    datasets: [
      {
        label: 'Win/Loss Ratio',
        data: [user.stats.gamesWon, user.stats.gamesPlayed - user.stats.gamesWon],
        backgroundColor: ['#4CAF50', '#FF5252'],
        hoverOffset: 4,
      },
    ],
  };

  // Chart data configuration for accuracy over time (Line Chart)
  const accuracyChartData = {
    labels: accuracyData.map((turn) => new Date(turn.timestamp).toLocaleTimeString()),
    datasets: [
      {
        label: 'Accuracy Over Time (%)',
        data: accuracyData.map((turn) => turn.accuracyRate),
        fill: false,
        borderColor: '#007bff',
        tension: 0.2,
      },
    ],
  };

  return (
    <Container>
      <Header>{user.name}'s Profile</Header>
      <Info>Email: {user.email}</Info>
      <Info>Games Played: {user.stats.gamesPlayed}</Info>
      <Info>Games Won: {user.stats.gamesWon}</Info>
      <Info>Highest Score: {user.stats.highestScore}</Info>
      <Info>Win Percentage: {winPercentage}%</Info>
      <Info>Average Accuracy: {calculateAverageAccuracy()}%</Info>

      {/* Win/Loss Pie Chart Section */}
      <ChartContainer>
        <Pie data={chartData} />
      </ChartContainer>

      {/* Accuracy Over Time Line Chart */}
      <ChartContainer>
        <Line data={accuracyChartData} />
      </ChartContainer>

      {/* Special Throws Section */}
      <SpecialThrowsSection>
        <SpecialThrowsHeader>Special Throws</SpecialThrowsHeader>
        <SpecialThrow>Triple Freeze Throws: {user.stats.tripleFreezeThrows}</SpecialThrow>
        <SpecialThrow>Lost All Points Throws: {user.stats.lostAllPointsThrows}</SpecialThrow>
        <SpecialThrow>Tripla-Triplat Throws: {user.stats.triplaTriplatThrows}</SpecialThrow>
        <SpecialThrow>Triplat Throws: {user.stats.triplatThrows}</SpecialThrow>
      </SpecialThrowsSection>

      <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
    </Container>
  );
};

export default Profile;
