import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { User as FirebaseUser, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { ref, set, get, update } from 'firebase/database';
import { auth, database } from './firebaseConfig';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from './firebaseConfig';

interface GameData {
  date: string;
  winner: string;
  players: Array<{
    name: string;
    score: number;
    isFinalThrow: boolean;
    hasWon: boolean;
  }>;
}

interface UserStats {
  gamesPlayed: number;
  gamesWon: number;
  highestScore: number;
  tripleFreezeThrows: number;
  lostAllPointsThrows: number;
  triplaTriplatThrows: number;
  triplatThrows: number;
}

interface User {
  uid: string;
  name: string;
  email: string;
  role: string;
  stats: UserStats;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  signup: (name: string, email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUserGameData: (gameData: GameData) => Promise<void>;
  updateSpecialThrowStats: (eventType: keyof UserStats) => Promise<void>;
  logPlayerAccuracyData: (diceValues: number[]) => Promise<void>;
  postEvent: (eventData: { title: string; description: string; date: string; imageFile?: File; imageUrl?: string; maxParticipants?: number }) => Promise<void>;  // Ensure postEvent type matches the actual function
  joinEvent: (eventId: string) => Promise<void>;

}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  login: async () => {},
  signup: async () => {},
  logout: async () => {},
  updateUserGameData: async () => {},
  updateSpecialThrowStats: async () => {},
  logPlayerAccuracyData: async () => {},  
  postEvent: async () => {}, 
  joinEvent: async () => {},
  
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const isAuthenticated = !!user;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userData = await fetchUserData(firebaseUser);
        setUser(userData);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (firebaseUser: FirebaseUser): Promise<User> => {
    const userRef = ref(database, `users/${firebaseUser.uid}`);
    const snapshot = await get(userRef);
  
    if (snapshot.exists()) {
      const data = snapshot.val();
      const stats = data.stats || {};
      return {
        uid: firebaseUser.uid,
        name: data.name,
        email: data.email,
        role: data.role || 'user',  // Fetch the user's role, default to 'user'
        stats: {
          gamesPlayed: stats.gamesPlayed || 0,
          gamesWon: stats.gamesWon || 0,
          highestScore: stats.highestScore || 0,
          tripleFreezeThrows: stats.tripleFreezeThrows || 0,
          lostAllPointsThrows: stats.lostAllPointsThrows || 0,
          triplaTriplatThrows: stats.triplaTriplatThrows || 0,
          triplatThrows: stats.triplatThrows || 0,
        },
      };
    } else {
      const newUser: User = {
        uid: firebaseUser.uid,
        name: firebaseUser.displayName || 'Anonymous',
        email: firebaseUser.email || '',
        role: 'user',  // Default role for new users
        stats: {
          gamesPlayed: 0,
          gamesWon: 0,
          highestScore: 0,
          tripleFreezeThrows: 0,
          lostAllPointsThrows: 0,
          triplaTriplatThrows: 0,
          triplatThrows: 0,
        },
      };
      await set(userRef, newUser);
      return newUser;
    }
  };

  const login = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const signup = async (name: string, email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    if (auth.currentUser) {
      await updateProfile(auth.currentUser, { displayName: name });
      const userRef = ref(database, `users/${userCredential.user.uid}`);
      const newUser: User = {
        uid: userCredential.user.uid,
        name,
        email,
        role: 'user',  // Default role for new users
        stats: {
          gamesPlayed: 0,
          gamesWon: 0,
          highestScore: 0,
          tripleFreezeThrows: 0,
          lostAllPointsThrows: 0,
          triplaTriplatThrows: 0,
          triplatThrows: 0,
        },
      };
      await set(userRef, newUser);
      setUser(newUser);
    }
  };

  const logout = async () => {
    await signOut(auth);
    setUser(null);
  };

  const updateUserGameData = async (gameData: GameData) => {
    if (user) {
      const userRef = ref(database, `users/${user.uid}/stats`);

      const updatedUser = { ...user };
      updatedUser.stats.gamesPlayed += 1;

      const didUserWin = gameData.winner === user.name;
      if (didUserWin) {
        updatedUser.stats.gamesWon += 1;
      }

      const userInGame = gameData.players.find((p) => p.name === user.name);
      if (userInGame && userInGame.score > user.stats.highestScore) {
        updatedUser.stats.highestScore = userInGame.score;
      }

      await update(userRef, updatedUser.stats);
      setUser(updatedUser);
    }
  };

  const updateSpecialThrowStats = async (eventType: keyof UserStats) => {
    if (user) {
      const userRef = ref(database, `users/${user.uid}/stats`);

      const updatedStats = {
        ...user.stats,
        [eventType]: (user.stats[eventType] || 0) + 1,
      };

      await update(userRef, updatedStats);
      setUser((prevUser) => (prevUser ? { ...prevUser, stats: updatedStats } : null));
    }
  };

  // Function to log accuracy data
  const logPlayerAccuracyData = async (diceValues: number[]) => {
    if (user) {
      const hitMiss = diceValues.map((value) => value > 0); // Check if dice hit or missed
      const newTurnData = {
        diceValues,
        hitMiss,
        timestamp: new Date().toISOString(),
      };

      const newTurnRef = ref(database, `users/${user.uid}/accuracyData/turn${Date.now()}`);
      await set(newTurnRef, newTurnData);
    }
  };

  const postEvent = async (eventData: { title: string; description: string; date: string; imageFile?: File; imageUrl?: string; maxParticipants?: number }) => {
    if (user && user.role === 'admin') {
      let imageUrl = eventData.imageUrl || '';
  
      try {
        // If an image file is provided, upload it to Firebase Storage
        if (eventData.imageFile) {
          const storageReference = storageRef(storage, `events/${Date.now()}_${eventData.imageFile.name}`);
          try {
            const snapshot = await uploadBytes(storageReference, eventData.imageFile);
            imageUrl = await getDownloadURL(snapshot.ref);
            console.log('Image uploaded successfully:', imageUrl);
          } catch (uploadError) {
            console.error('Error uploading image:', uploadError);
            throw new Error('Image upload failed');
          }
        }
  
        // Create a new event entry
        const eventRef = ref(database, `events/event${Date.now()}`); // Use a unique key for the event
  
        // Dynamically set the event data
        await set(eventRef, {
          title: eventData.title,
          description: eventData.description,
          date: eventData.date,
          imageUrl,  // Store the image URL in the event
          maxParticipants: eventData.maxParticipants || Infinity
        });
  
        alert('Event posted successfully');
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Error posting event:', error.message);
          alert(`Error posting event: ${error.message}`);
        } else {
          console.error('Unknown error:', error);
          alert('An unknown error occurred');
        }
      }
    } else {
      throw new Error('Unauthorized: Only admins can post events');
    }
  };

  const joinEvent = async (eventId: string) => {
    if (user) {
      try {
        // Get a reference to the event participants
        const eventRef = ref(database, `events/${eventId}/participants/${user.uid}`);
        const eventSnapshot = await get(ref(database, `events/${eventId}`));
    
        if (!eventSnapshot.exists()) {
          throw new Error('Event not found');
        }
  
        const eventData = eventSnapshot.val();
        const participantCount = eventData.participants ? Object.keys(eventData.participants).length : 0;
        const maxParticipants = eventData.maxParticipants || Infinity;
    
        // Check if the event has reached its max participant limit
        if (participantCount >= maxParticipants) {
          throw new Error('Event is full');
        }
    
        // Add the user to the participants list
        await set(eventRef, { name: user.name });
        alert('You have joined the event!');
      } catch (error) {
        console.error('Error joining event:', error);
        alert(`Error joining event: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    } else {
      alert('You must be logged in to join an event');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        signup,
        logout,
        updateUserGameData,
        updateSpecialThrowStats,
        logPlayerAccuracyData,  // Provide logPlayerAccuracyData function
        postEvent,
        joinEvent,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
