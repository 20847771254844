import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { AuthContext } from '../AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { Player } from '../types';
import ThemeSwitcher from './ThemeSwitcher'; // Import the ThemeSwitcher
import SoundSwitcher from './SoundSwitcher'; // Import the SoundSwitcher

interface NavBarProps {
  players: Player[];
  soundEnabled: boolean;
  setSoundEnabled: (enabled: boolean) => void;
}

const MenuIcon = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 28px;
  cursor: pointer;
  position: fixed;  /* Fix the position of the menu icon */
  top: 15px;        /* Adjust the distance from the top */
  right: 20px;      /* Adjust the distance from the right */
  z-index: 20;      /* Ensure it stays on top */
`;

const Drawer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 300px;
  height: 100%;
  background: ${({ theme }) => theme.colors.surface};
  padding: ${({ theme }) => theme.spacing(4)};
  z-index: 1000;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
`;

const DrawerMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const DrawerItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const DrawerLink = styled(Link)`
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: none;
  font-size: 20px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(2)};
  font-size: 28px;
  cursor: pointer;
`;

const NavBar: React.FC<NavBarProps> = ({ players, soundEnabled, setSoundEnabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, user } = useContext(AuthContext);
  const location = useLocation();

  const toggleMenu = () => setIsOpen(!isOpen);

  const isGameInProgress = players.length > 0;
  const isOnGamePage = location.pathname === '/game';
  const isOnStartScreen = location.pathname === '/';

  return (
    <>
      <MenuIcon onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </MenuIcon>
      <AnimatePresence>
        {isOpen && (
          <Drawer
            initial={{ x: '100%' }}
            animate={{ x: '0%' }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.3 }}
          >
            <CloseIcon onClick={toggleMenu} />
            <DrawerMenu>
              {isGameInProgress && !isOnGamePage && (
                <DrawerItem>
                  <DrawerLink to="/game" onClick={toggleMenu}>
                    Back to Game
                  </DrawerLink>
                </DrawerItem>
              )}
              {!isGameInProgress && !isOnStartScreen && (
                <DrawerItem>
                  <DrawerLink to="/" onClick={toggleMenu}>
                    Home
                  </DrawerLink>
                </DrawerItem>
              )}
              <DrawerItem>
                <DrawerLink to="/rules" onClick={toggleMenu}>
                  Rules
                </DrawerLink>
              </DrawerItem>
              <DrawerItem>
                <DrawerLink to="/buy" onClick={toggleMenu}>
                  Buy the Game
                </DrawerLink>
              </DrawerItem>
              <DrawerItem>
                <DrawerLink to="/events" onClick={toggleMenu}>
                  Events
                </DrawerLink>
              </DrawerItem>
              {isAuthenticated ? (
                <>
                  <DrawerItem>
                    <DrawerLink to="/profile" onClick={toggleMenu}>
                      Profile
                    </DrawerLink>
                  </DrawerItem>
                  {user?.role === 'admin' && (
                    <DrawerItem>
                      <DrawerLink to="/admin" onClick={toggleMenu}>
                        Admin Panel
                      </DrawerLink>
                    </DrawerItem>
                  )}
                </>
              ) : (
                <>
                  <DrawerItem>
                    <DrawerLink to="/login" onClick={toggleMenu}>
                      Login
                    </DrawerLink>
                  </DrawerItem>
                  <DrawerItem>
                    <DrawerLink to="/signup" onClick={toggleMenu}>
                      Signup
                    </DrawerLink>
                  </DrawerItem>
                </>
              )}
              <DrawerItem>
                <ThemeSwitcher /> {/* Theme switcher component */}
              </DrawerItem>
              <DrawerItem>
                <SoundSwitcher soundEnabled={soundEnabled} setSoundEnabled={setSoundEnabled} /> {/* Sound toggle */}
              </DrawerItem>
            </DrawerMenu>
          </Drawer>
        )}
      </AnimatePresence>
    </>
  );
};

export default NavBar;
