import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

interface SpecialMessagePopupProps {
  message: string;
  onDismiss: () => void;
}

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const MessageContainer = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: 20px 40px;
  border-radius: 12px;
  font-size: 26px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  white-space: nowrap;
`;

const SpecialMessagePopup: React.FC<SpecialMessagePopupProps> = ({ message, onDismiss }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss();
    }, 2500); // Show message for 2 seconds

    return () => clearTimeout(timer);
  }, [onDismiss]);

  return (
    <AnimatePresence>
      {message && (
        <ModalOverlay
          key="specialMessageOverlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <MessageContainer
            key="specialMessage"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            {message}
          </MessageContainer>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default SpecialMessagePopup;
