import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../AuthContext';
import Cover from '../media/TripleFreezerCover2.jpg';
import Logo from '../media/Dice_logo_finished-1.png';
import { FaInstagram } from 'react-icons/fa';

interface Props {
    onStartGame: (playerList: { id: string; name: string }[]) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background: url(${Cover}) no-repeat center center;
  background-size: cover;
  overflow: hidden;

`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing(1.5)};
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;


`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  @media (min-width: 1024px) {
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;

const LogoImage = styled.img`
  width: 100px;
  height: auto;
  max-width: 150px;

  @media (min-width: 1024px) {
    width: 200px; /* Increase logo size on larger viewports */
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Input = styled.input`
  padding: ${({ theme }) => theme.spacing(1)};
  font-size: 16px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.colors.textSecondary};
  border-radius: 4px;
  flex-grow: 1;
  max-width: 100%;
  transition: all 0.3s ease;


  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Button = styled.button`
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;



  &:disabled {
    background-color: ${({ theme }) => theme.colors.textSecondary};
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }

  &:active:not(:disabled) {
    transform: scale(0.98);
  }
`;

const PlayerList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

const PlayerItem = styled.li<{ $isDragging: boolean }>`
  padding: ${({ theme }) => theme.spacing(1)};
  font-size: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textSecondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ $isDragging }) => ($isDragging ? 0.5 : 1)};
  cursor: grab;
`;

const HostIndicator = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;

const MoveButton = styled.button`
  padding: 4px 8px;
  margin: 0 4px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.textSecondary};
    cursor: not-allowed;
  }
`;

// Instagram Button styling
const SocialButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e1306c;  // Instagram's color
  color: white;
  font-size: 18px;
  padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(3)};
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: ${({ theme }) => theme.spacing(4)};
  font-family: 'Poppins', sans-serif;
  
  &:hover {
    background-color: #c82361;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }

  @media (min-width: 600px) {
    font-size: 20px;
  }
`;

const StartScreen: React.FC<Props> = ({ onStartGame }) => {
  const [playerName, setPlayerName] = useState('');
  const [playerList, setPlayerList] = useState<{ id: string; name: string }[]>([]);
  const { isAuthenticated, user } = useContext(AuthContext);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  // Use effect to ensure host is added only once when logged in
  useEffect(() => {
    if (isAuthenticated && user) {
      setPlayerList((prevList) => {
        const isHostInList = prevList.some((player) => player.id === user.uid);
        if (!isHostInList) {
          return [{ id: user.uid, name: user.name }, ...prevList];
        }
        return prevList;
      });
    }
  }, [isAuthenticated, user]); 

  const addPlayer = () => {
    if (playerName.trim() !== '') {
      setPlayerList((prevList) => [
        ...prevList,
        { id: Date.now().toString(), name: playerName.trim() },
      ]);
      setPlayerName('');
    }
  };

  const handleStartGame = () => {
    onStartGame(playerList);
  };

  // Move player up in the list
  const movePlayerUp = (index: number) => {
    if (index > 0) {
      const updatedList = [...playerList];
      const temp = updatedList[index - 1];
      updatedList[index - 1] = updatedList[index];
      updatedList[index] = temp;
      setPlayerList(updatedList);
    }
  };

  // Move player down in the list
  const movePlayerDown = (index: number) => {
    if (index < playerList.length - 1) {
      const updatedList = [...playerList];
      const temp = updatedList[index + 1];
      updatedList[index + 1] = updatedList[index];
      updatedList[index] = temp;
      setPlayerList(updatedList);
    }
  };

  return (
    <Container>
        <ImageContainer>
          <LogoImage src={Logo} alt="Game Logo" />
      </ImageContainer>
      <ContentWrapper>
        {/* <Title>Welcome to Triple Freezer</Title> */}
        {/* <ImageContainer>
        </ImageContainer> */}
        <div>
          <InputContainer>
            <Input
              type="text"
              placeholder="Enter player name"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
            />
            <Button onClick={addPlayer}>Add Player</Button>
          </InputContainer>
          <PlayerList>
            {playerList.map((player, index) => (
              <PlayerItem
                key={player.id}
                draggable
                onDragStart={() => setDraggedIndex(index)}
                onDragOver={() => {
                  if (draggedIndex !== index) {
                    const updatedList = [...playerList];
                    const draggedPlayer = updatedList[draggedIndex!];
                    updatedList.splice(draggedIndex!, 1);
                    updatedList.splice(index, 0, draggedPlayer);
                    setPlayerList(updatedList);
                    setDraggedIndex(index);
                  }
                }}
                onDragEnd={() => setDraggedIndex(null)}
                $isDragging={draggedIndex === index}
              >
                {player.name} {player.id === user?.uid && <HostIndicator>(Host)</HostIndicator>}
                <div>
                  <MoveButton onClick={() => movePlayerUp(index)} disabled={index === 0}>
                    ↑
                  </MoveButton>
                  <MoveButton onClick={() => movePlayerDown(index)} disabled={index === playerList.length - 1}>
                    ↓
                  </MoveButton>
                </div>
              </PlayerItem>
            ))}
          </PlayerList>
          <Button
            onClick={handleStartGame}
            disabled={playerList.length === 0 && !(isAuthenticated && user)}
          >
            Start Game
          </Button>
        </div>
      </ContentWrapper>

      {/* Instagram Button */}
      <SocialButton
        href="https://www.instagram.com/thetriplefreezer/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram /> Follow Us on Instagram
      </SocialButton>
    </Container>
  );
};

export default StartScreen;
