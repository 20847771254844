// Login.tsx
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { logLoginEvent, auth } from '../firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';

const Container = styled.div`
  max-width: 400px;
  margin: 80px auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.surface};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.textSecondary};
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-sizing: border-box; /* Ensure padding is included in width calculation */

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
  }
`;

const Button = styled.button`
  padding: 10px;
  background: linear-gradient(45deg, ${({ theme }) => theme.colors.primary}, ${({ theme }) => theme.colors.primaryDark});
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  width: 100%;

  &:hover {
    background: linear-gradient(45deg, ${({ theme }) => theme.colors.primaryDark}, ${({ theme }) => theme.colors.primary});
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3); /* Focus ring */
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
`;

const SignupLink = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 0.9rem;

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ForgotPasswordLink = styled.p`
  text-align: right;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

const BackButton = styled.button`
  padding: 10px;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryDark};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
`;

const Login: React.FC = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const handlePasswordReset = async () => {
    setError(null);
    if (!email) {
      setError('Please enter your email.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setError('If an account with that email was found, a password reset email has been sent.');
    } catch (error) {
      console.error('Password reset error:', error);
      setError('Something went wrong. Please try again.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      await login(email, password);
      logLoginEvent();
      navigate('/profile'); // Redirect to profile after login
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid email or password.');
    }
  };

  return (
    <Container>
      <Title>{isForgotPassword ? 'Reset Password' : 'Login'}</Title>
      <Form onSubmit={isForgotPassword ? handlePasswordReset : handleSubmit}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {!isForgotPassword && (
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Button type="submit">
          {isForgotPassword ? 'Send Reset Link' : 'Login'}
        </Button>
        {isForgotPassword ? (
          <BackButton onClick={() => setIsForgotPassword(false)}>
            Back to Login
          </BackButton>
        ) : (
          <ForgotPasswordLink onClick={() => setIsForgotPassword(true)}>
            Forgot your password?
          </ForgotPasswordLink>
        )}
      </Form>
      {!isForgotPassword && (
        <SignupLink>
          Don't have an account? <Link to="/signup">Sign up here</Link>.
        </SignupLink>
      )}
    </Container>
  );
};

export default Login;
